import jq from 'jquery';
import 'readmore-js';

import i18n from '../i18n';

// Init report button
$('#report-abuse').on('click', (e) => {
    e.preventDefault();
    $('#modalAbuse').modal('show');
});

$(document).ready(() => {
    const headerHeight = $('#head-nav-band').css('position') === 'fixed' ? $('#head-nav-band').height() : 0;

    // init read more
    $('.readmore').each((index, element) => {
        const $element = jq(element);
        const moreLink = $element.data('more-link') || i18n.t('see_more');
        let collapsedHeight = parseInt($element.data('collapsed-height'), 10);
        if (isNaN(collapsedHeight)) {
            collapsedHeight = 120;
        }
        $element.readmore({
            embedCSS: false,
            collapsedHeight,
            moreLink: `<a href="#">${moreLink}</a>`,
            lessLink: `<a href="#">${i18n.t('common:close')}</a>`,
            afterToggle() {
                let event;
                // trigger resize event
                if (typeof window.CustomEvent === 'function') {
                    event = new CustomEvent('resize');
                } else {
                    event = document.createEvent('CustomEvent');
                    event.initCustomEvent('resize', false, false, undefined);
                }
                window.dispatchEvent(event);
            }
        });
    });

    // init scrollspy
    $('body').scrollspy({
        target: '.navigation-home',
        offset: headerHeight + $('.navigation-home').height()
    });

    // init smooth scroll anchor
    $('a[href^="#"]').on('click', (e) => {
        e.preventDefault();
        const hash = e.target.href ? e.target.href.split('#')[1] : null;
        if (hash) {
            $('html, body').animate({ scrollTop: $(`#${hash}`).offset().top - 90 }, 'slow');
        }
    });

    const container = $('.nav-home');
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                const activeTab = $('.nav-home li.active');
                if (activeTab.length) {
                    const scrollLeft = activeTab.position().left + container.scrollLeft();
                    container.animate({ scrollLeft }, 300);
                }
            }
        });
    });

    // Start observing changes to the class attribute of the <li> elements
    $('.nav-home li').each((index, element) => {
        observer.observe(element, { attributes: true });
    });

    // Call the observer once on DOM ready to handle the initial active tab
    observer.observe(container[0], { childList: true });
});
